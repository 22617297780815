<template>
    <div class="left-space-desktop">
      <div class="page-content w-100">
        <!-- start heading -->
        <div class="row">
          <div class="col-sm-12">
            <p class="text-center page-heading">Profile and settings</p>
          </div>
        </div>
        <!-- end heading -->
        <div class="row mt-2 ">
          <div class="col-sm-12 settingsStyle">
            <nav class="nav-doctorTab">
              <ul class="doctorTabs">
                <li class="tab"  v-for="tab in doctorTabs" :key="tab" :class="!tab.isVisble ? 'visible-none':''" @click="navigatePage(tab.slug)">
                <p v-if="tab.isVisble" :class="tab.isActive ? 'activedoctorTab' : ''" >{{ tab.name }}</p>
                </li>
              </ul>
            </nav>
            <div class="text-border"></div>
            <div class="tab-content" id="nav-tabContent">
              <div v-if="currentPage === 'about-me'">
                <about-me></about-me>
              </div>
              <div v-if="currentPage === 'my-Practice'">
                <myPractice></myPractice>
              </div>
              <div v-if="currentPage === 'groups'">
                <groups></groups>
              </div>
              <div v-if="currentPage === 'messaging'">
                <messages></messages>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import AboutMe from "./clinician-others-Info.vue";
  import myPractice from "./clinician-others-myPractice.vue";
  import groups from "./clinician-others-groups.vue"
  import messages from "./clinician-others-messages.vue"
  // import Swal from "sweetalert2/dist/sweetalert2.js";
  import axios from "axios";
  export default {
    components: {
      AboutMe,
      myPractice,
      groups,
      messages
    },
    data() {
      return {
        currentRoute: "",
        enablePractice:{},
        doctorTabs:[
          {name:'About Me', slug: 'about-me',isVisble:true,isActive:true},
          {name:'My Practice', slug: 'my-Practice',isVisble:true,isActive:false},
          {name:'Groups', slug: 'groups',isVisble:true,isActive:false},
        {name:'Messaging', slug: 'messaging',isVisble:true,isActive:false},
        ],
        currentPage: "about-me",
        clinicianOthersData:{}
      }
    },
    methods: {
      async navigatePage(slug){
        const message = await this.getAccess();
        console.log(message,"Message>>");
             if(message){
              this.$swal(message)
              return
             }
        this.doctorTabs.map((tab, idx)=>{
          if(tab.slug == slug) {
              if(slug == 'group') {
              let findCurrentPageIdx = this.doctorTabs.findIndex((data)=> data.slug === this.currentPage)
              this.doctorTabs[findCurrentPageIdx].isActive=true
              tab.isVisble = false
              for(let index = (idx + 1); index < this.doctorTabs.length; index++){
                this.doctorTabs[index].isVisble = true 
              }
            } else {
               tab.isActive = true
               this.currentPage = slug
            }
          } else {
            tab.isActive = false
          }
        })
      },
      async getAccess() {
        let restrictAccess = ""
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      await axios.get(`${BASE_API_URL}/clinician-others/${this.clinicianOthersID}/one`)
                .then((response) => {
                this.clinicianOthersData=response.data
              if (!this.clinicianOthersData.speciality) {
                restrictAccess = "Please complete the About Me section of the Settings Page"
              }  else if (this.clinicianOthersData.speciality) {
                restrictAccess = ""
              } 
          });
          return restrictAccess
      },
    },
    created: function () {
      const clinicianID = localStorage.getItem('clinician-others-id')
     this.clinicianOthersID = JSON.parse(clinicianID);
    },
  }
  </script>
  <style>
  .visible-none{
    display: none;
  }
  ul.doctorTabs{
    list-style:none ;
    cursor: pointer;
    display: flex;
    padding: 0;
    margin-bottom: 5px;
  
  }
  li.tab p{
    padding: 8px 12px;
    margin-bottom: 0;
  }
  .activedoctorTab{
    background-color: #34989f;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .col-sm-12.settingsStyle {
    margin: 0px 0px 0px 10px;
    background-color: #ffff;
    border-radius: 10px;
    padding-bottom: 15px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%);
  }
  
  .nav.nav-tabs {
    padding-top: 15px;
  }
  .nav-doctorTab{
    padding-top: 15px;
  }
  
  .left-space-desktop {
    margin-left: 20px;
  }
  
  .text-border {
    background-color: #00979e;
    height: 4px;
    margin-bottom: 20px;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  }
  
  .nav-tabs .nav-link.active {
    color: white;
    background-color: #00979e;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  @media screen and (max-width:991px) {
    .col-sm-12.settingsStyle {
      margin: auto;
      background-color: #ffff;
      border-radius: none;
      padding-bottom: 15px;
      box-shadow: none;
    }
  
    .nav.nav-tabs {
      padding-top: 0px;
    }
    .nav-doctorTab{
    padding-top: 0px;
  }
  
    .left-space-desktop {
      margin-left: 0px;
    }
  
  }</style>